.workLink {
  margin-left: -3rem;
}

.max-width {
  position: relative;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.home-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.home .max-width {
  margin: auto 0 auto 30px;
}
.home .home-content .text-1 {
  font-size: 27px;
  margin-left: -2.5rem;
}

@media (max-width: 380px) {
  .home .home-content .text-1 {
    font-size: 25px;
  }
}

.home .home-content .text-2 {
  font-size: 45px;
  font-weight: 600;
  /* margin-left: -3px; */
  margin-left: -2.5rem;
}

/* @media (max-width: 947px) {
  .home .home-content .text-2 {
    font-size: 70px;
  }
} */

@media (max-width: 690px) {
  .home .home-content .text-2 {
    font-size: 45px;
  }
}

/* @media (max-width: 500px) {
  .home .home-content .text-2 {
    font-size: 50px;
  }
} */

@media (max-width: 380px) {
  .home .home-content .text-2 {
    font-size: 33px;
  }
}

.home .home-content .text-3 {
  font-size: 40px;
  margin: 5px 0;
  display: flex;
  /* margin-bottom: 1.4rem; */
  margin-bottom: 1rem;
  margin-left: -2.5rem;
}

@media (max-width: 690px) {
  .home .home-content .text-3 {
    font-size: 32px;
  }
}

@media (max-width: 947px) {
  .home .home-content .text-3 {
    font-size: 35px;
  }
}

@media (max-width: 500px) {
  .home .home-content .text-3 {
    font-size: 27px;
  }
}

@media (max-width: 380px) {
  .home .home-content .text-3 {
    font-size: 23px;
  }
}

@media (max-width: 280px) {
  .home .home-content .text-3 {
    font-size: 20px;
  }
}

.home .home-content .text-3 span {
  color: #04d9ff;
  font-weight: 500;
}
/* .home .home-content a {
  display: inline-block;
  background: rgba(251, 214, 121, 1);
  color: black;
  font-size: 25px;
  padding: 12px 36px;
  margin-top: 20px;
  font-weight: 400;
  border-radius: 6px;

  transition: all 0.3s ease;
  text-decoration: none;
}
.home .home-content a:hover {
  color: crimson;
  background: none;
} */

/* .home .home-content a {
  display: inline-block;
  background: rgba(251, 214, 121, 1);
  color: black;
  font-size: 25px;
  padding: 12px 36px;
  margin-top: 20px;
  font-weight: 400;
  border-radius: 6px;

  transition: all 0.3s ease;
  text-decoration: none;
}
.home .home-content a:hover {
  color: crimson;
  background: none;
} */

.typing {
  margin-left: 11px;
}

.card {
  /* width: 70%; */
  /* height: 16rem; */
  /* background: #fff; */
  /* color: black;
  font-size: 20px; */
  /* margin: 0 20px; */
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
  /* border: 3px solid #04d9ff; */
  /* border-radius: 9px; */
  transition: 0.5s;
  transform: perspective(1px) translateZ(0);
}

/* @media screen and (max-width: 1200px) {
  .card {
    width: 85%;
    height: 18rem;
  }
}  */

.card:hover {
  animation-name: hvr-wobble-skew;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  background-color: #04d9ff;
  color: white;
}

.card:hover .skillsIcon {
  color: black;
}

@keyframes hvr-wobble-skew {
  16.65% {
    -webkit-transform: skew(-12deg);
    transform: skew(-12deg);
  }
  33.3% {
    -webkit-transform: skew(10deg);
    transform: skew(10deg);
  }
  49.95% {
    -webkit-transform: skew(-6deg);
    transform: skew(-6deg);
  }
  66.6% {
    -webkit-transform: skew(4deg);
    transform: skew(4deg);
  }
  83.25% {
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg);
  }
  100% {
    -webkit-transform: skew(0);
    transform: skew(0);
  }
}

/* .rec-arrow-right,
.rec-arrow-left {
  display: none;
} */

/* .rec-arrow-right,
.rec-arrow-left {
  margin-left: 1.3rem;
  margin-right: 1.3rem;
} */

/* @media screen and (max-width: 1200px) {
  .rec-arrow-right,
  .rec-arrow-left {
    display: none;
  }
} */

.rec-arrow-right,
.rec-arrow-left {
  color: #04d9ff !important;
  background-color: white !important;
  transform: perspective(1px) translateZ(0);
  position: relative;
}

.rec-arrow-right:before,
.rec-arrow-left:before {
  content: "";
  position: absolute;
  border: #04d9ff solid 4px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition-duration: 0.3s;
  transition-property: top, right, bottom, left;
  border-radius: 50%;
}

.rec-arrow-right:hover,
.rec-arrow-left:hover {
  color: white !important;
  background-color: #04d9ff !important;
}

.rec-arrow-right:hover:before,
.rec-arrow-left:hover:before {
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;
}

.rec-arrow-right:after,
.rec-arrow-left:after {
  content: "";
  position: absolute;
  border: #04d9ff solid 3px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation-duration: 1s;
  border-radius: 50%;
}

.rec-arrow-right:hover:after,
.rec-arrow-left:hover:after {
  animation-name: hvr-ripple-out;
}

@keyframes hvr-ripple-out {
  100% {
    top: -20px;
    right: -20px;
    bottom: -20px;
    left: -20px;
    opacity: 0;
  }
}

/* @media screen and (max-width: 1000px) {
  .rec-arrow-right,
  .rec-arrow-left {
    display: none;
  }
} */

.rec-dot {
  box-shadow: 0 0 1px 4px #04d9ff !important;
  transition: 0.2s !important;
}

.rec-dot:hover {
  box-shadow: 0 0 1px 5px #04d9ff !important;
  background-color: #04d9ff !important;
}

.rec-dot_active {
  width: 20px !important;
  background-color: #04d9ff !important;
  box-shadow: 0 0 1px 4px #04d9ff !important;
}

.rec-dot_active:hover {
  box-shadow: 0 0 1px 4px #04d9ff !important;
}

.skills {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40rem;
  /* margin-top: 7rem; */
  margin-top: 9rem;
  /* margin-left: 15rem;
  margin-right: 15rem; */
  /* padding-bottom: 3rem; */
  padding-bottom: 9rem;
  margin-bottom: 2rem;
  /* margin-left: 9rem;
  margin-right: 9rem; */

  /* padding-left: 14rem;
  padding-right: 14rem; */
  background: radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );
}

.skillsContainer {
  /* margin-top: 4rem; */
  /* width: 100%; */
  /* scroll-margin: 9rem; */

  height: 100vh;

  /* background: radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  ); */

  background: rgba(4, 44, 84, 1);

  scroll-margin: 2.5rem;
}

/* @media (max-height: 400px) {
  .skillsContainer {
    margin-top: -1rem;
  }
} */

@media screen and (max-width: 800px) {
  .skillsContainer {
    scroll-margin: 2.5rem;
  }
}

/* @media screen and (max-width: 759px) {
  .skills {
    margin-top: 3rem;
  }
}  */

@media screen and (max-width: 430px) {
  .skills {
    margin-bottom: 5rem;
  }
}

/* @media screen and (max-width: 1000px) {
  .skills {
    margin-left: 4rem;
    margin-right: 4rem;
  }
}  */

/* @media screen and (max-width: 670px) {
  .skills {
    margin-left: 2rem;
    margin-right: 2rem;
  }
} */

@media screen and (max-width: 400px) {
  .skills {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }
}

/* @media screen and (min-width: 1000px) {
  .skills {
    margin-left: 9rem;
    margin-right: 9rem;
  }
}  */

@media screen and (max-width: 800px) {
  .skills {
    margin-top: 4rem;
  }
}

.skillsTitle {
  /* font-size: clamp(1.2rem, 5vw, 3rem); */
  /* margin-bottom: 0.6rem; */
}

.skillLetters {
  margin-top: 5rem;
  color: white;
}

@media (max-width: 800px) {
  .skillLetters {
    margin-top: 4rem;
  }
}

.skillsTitle {
  /* margin-top: -2rem !important; */
  margin-bottom: -1rem !important;
  margin-top: 0 !important;
}

.underline {
  width: 9rem;
  height: 0.25rem;
  margin-bottom: 1.25rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.5rem;
  background: #ffde00;
}

.skillsMain {
  font-size: 2rem;
  font-weight: bold;
}

.skillsMain {
  margin-bottom: 4.3rem;
}

.skillsSecond {
  margin-top: -2.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.skillsIcon {
  color: #04d9ff;
  transition: 0.5s;
}

/* About */

.about-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about {
  /* scroll-margin: 20rem; */

  /* padding-bottom: 3rem; */
  /* background: radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  ); */
  background: rgba(4, 44, 84, 1);

  padding-top: 0.3rem;
  /* padding-bottom: 2rem; */
  margin-top: 1.2rem;

  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
}

/* @media screen and (max-width: 1300px) {
  .about {
    height: auto;
  }
} */

/* @media screen and (max-width: 1260px) {
  .about {
    height: auto;
  }
} */

/* @media screen and (max-width: 1450px) {
  .about {
    height: auto;
  }
} */

/* @media (max-width: 1450px) {
  .aboutWrap {
    flex-direction: column;
  }
} */

@media screen and (max-width: 1000px) {
  .about {
    height: auto;
  }
}

@media screen and (max-width: 1000px) {
  .about {
    scroll-margin: 3rem;
  }
}

.max-width {
  max-width: 1300px;
  padding: 0 80px;
  margin: auto;
  margin-top: 6.5rem;
}

section .title {
  position: relative;
  text-align: center;
  /* font-size: clamp(2rem, 4vw, 4rem); */
  font-size: clamp(2rem, 4vw, 3rem);
  font-weight: 500;
  padding-bottom: 20px;
  font-family: "Ubuntu", sans-serif;
}

/* .about .about-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  margin-top: 9rem;
} */

.about .about-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  width: 31rem;

  /* margin-top: 9rem; */
}

.about .about-content .left {
  width: 45%;
}

.about .about-content .left img {
  height: 400px;
  /* width: 400px; */
  object-fit: cover;
  border-radius: 6px;

  width: 31rem;
}

.beforeImg {
  position: relative;
}

/* @media screen and (min-width: 1310px) {
  .beforeImg::before {
    content: "";
    position: absolute;
    width: 75%;
    height: 95%;
    border: 0.25rem solid #04d9ff;
    top: -2.2rem;
    right: -2rem;
    border-radius: 0.5rem;
    z-index: -100;

    animation-name: fading2;
    animation-duration: 7s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
}

@keyframes fading2 {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */

/* @media screen and (min-width: 992px) {
  .beforeImg::before {
    left: -2rem;
  }
} */

/* @media screen and (min-width: 1310px) {
  .beforeImg::after {
    content: "";
    position: absolute;
    width: 75%;
    height: 95%;
    border: 0.25rem solid #04d9ff;
    top: 2.4rem;
    right: 4.5rem;
    border-radius: 0.5rem;
    z-index: -100;

    animation-name: fading;
    animation-duration: 7s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
}

@keyframes fading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */

.about .about-content .right {
  width: 55%;
}

.about .about-content .right .text {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 10px;
}

.about .about-content .right p {
  text-align: justify;
}

.about .about-content .right a {
  /* display: inline-block; */
  /* background: crimson;
  color: #fff;
  font-size: 20px;
  font-weight: 500; */
  /* padding: 10px 30px;
  margin-top: 20px; */
  /* border-radius: 6px;
  border: 2px solid crimson;
  transition: all 0.3s ease; */
}

@media (max-width: 991px) {
  .max-width {
    padding: 0 50px;
  }
}

@media (max-width: 690px) {
  .max-width {
    padding: 0 23px;
  }
}

@media (max-width: 1104px) {
  .about .about-content .left img {
    /* height: 350px; */
    /* width: 350px; */
    position: relative;
    justify-self: center;
  }
}

@media (max-width: 280px) {
  .about .about-content .left img {
    height: 250px;
    width: 250px;
  }
}

@media (max-width: 947px) {
  .about .about-content .left {
    display: flex;
    justify-content: center;
    margin: 0 auto 60px;
  }
}

@media (max-width: 947px) {
  .about .about-content .right {
    flex: 100%;
  }
}

@media (max-width: 947px) {
  .max-width {
    max-width: 930px;
  }
}

@media (max-width: 947px) {
  .about .about-content .column {
    width: 100%;
  }
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.connect {
  min-height: 40vh;
  position: relative;
  /* padding: 10rem 0 5rem 0; */
  padding: 0rem 0 5rem 0;
  margin: 5rem 0;
  /* margin-top: -1rem; */
  margin-top: 4rem;
}

@media (max-width: 1000px) {
  .connect {
    margin-top: 0;
  }
}

.message {
  min-height: 10rem;
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
}
/* .connect::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.7;
  z-index: -1;
} */
.connect {
  display: grid;
  place-items: center;
}
.video-banner .section-title {
  margin-bottom: 2rem;
}
.video-text {
  max-width: 30rem;
}
.video-banner {
  /* background: hsl(45, 100%, 96%); */
  padding: 3rem 5rem 5rem 5rem;
  text-align: center;

  /* clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 75%,
    75% 75%,
    75% 100%,
    50% 75%,
    0% 75%
  ); */
}
/* .connect {
  clip-path: polygon(50% 0%, 100% 10%, 100% 90%, 50% 100%, 0 90%, 0 10%);
} */

.slider {
  position: relative;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image {
  width: 1000px;
  height: 600px;
  border-radius: 10px;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 40rem;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 40rem;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projectsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 3px solid black;
  width: 20rem;
  height: 20rem;
}

.projectsTitle {
  margin-bottom: 4rem;
}

@media screen and (max-width: 1200px) {
  .projectsTitle {
    margin-bottom: 3rem;
  }
}

.projectsSection {
  margin-top: 4rem;
}

.s2 {
  overflow: auto;
  display: block;
}

.main-container {
  margin: 0 auto;
}

.input-field {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #04d9ff;
  border-radius: 5px;
  border: 1px solid #04d9ff;
  font-size: 14px;
}

#submit-btn {
  margin-top: 10px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  color: black;
  background-color: #04d9ff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
}

#contact-form {
  display: block;
  max-width: 500px;
  margin: 0 auto;

  border-radius: 5px;

  margin-top: 3rem;
}

/* a {
  text-decoration: none;
  color: #17a2b8;
} */

#contact-form label {
  line-height: 2.7em;
}

#contact-form textarea {
  min-height: 100px;
  font-size: 14px;
}

/* @media screen and (max-width: 1200px) {
  .main-container {
    width: 95%;
  }
} */

#contact-form label {
  line-height: 2.7em;
}

.aboutHero {
  background-size: cover;
  padding: 10rem 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  align-items: center;
}

.aboutHero > * {
  color: #04d9ff;
}

.aboutHero > h1 {
  font-size: 4rem;
  padding-bottom: 1rem;
}

.aboutHero > article > p {
  font-size: 1.5rem;
  font-weight: 200;
}

.aboutHero > article > a {
  padding: 1rem;
  margin-top: 0.75rem;
}

/* .breweries {
  padding: 2rem;
}

.breweries > ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  grid-gap: 1rem;
} */

/* .breweries > ul > li {
  border: 1px solid #04d9ff;
  border-radius: 0.5rem;
} */

/* .breweries > ul > li > figure {
  max-height: 220px;
  overflow: hidden;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  position: relative;
}

.breweries > ul > li > figure > img {
  width: 100%;
}

.breweries > ul > li > figure > figcaption {
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
}

.breweries > ul > li > figure > figcaption > h3 {
  color: white;
  padding: 0.75rem;
  font-size: 1.25rem;
}

.breweries > ul > li > p {
  font-size: 1rem;
  line-height: 1.5;
  padding: 1rem 0.75rem;

  margin-top: 0.5rem;
}

.breweries > ul > li > a {
  padding: 0.5rem 1rem;
  margin: 0.5rem;
} */

li {
  list-style: none;
}

.projectLinks {
  height: 3.5rem;
  width: 100%;
  border-top: 1px solid #379683;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.projectIcon {
  color: black;
}

.ml14 {
  font-size: clamp(2rem, 4vw, 4rem);
  color: black !important;
}

.ml14 .text-wrapper {
  position: relative;
  display: inline-block;
  padding-top: 0.1em;
  padding-right: 0.05em;
  padding-bottom: 0.15em;
}

.ml14 .line {
  opacity: 0;
  position: absolute;
  left: 0;
  height: 3px;
  width: 100%;
  background-color: #04d9ff;
  transform-origin: 100% 100%;
  bottom: 0;
}

.ml14 .letter {
  display: inline-block;
}

/* .cursor {
  width: 23px;
  height: 23px;

  border-radius: 50%;
  position: absolute;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
  animation: cursorAnim 1.2s infinite alternate;
  pointer-events: none;
  z-index: 99999;
  background-color: #04d9ff;

  transition-property: background, transform;
}

@keyframes cursorAnim {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.7);
  }
}

@keyframes cursorAnim2 {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.4);
  }
}

@keyframes cursorAnim3 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.expand {
  animation: cursorAnim3 0.3s forwards;
} */

.pop_in {
  animation-name: hvr-pop;
  animation-duration: 0.35s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  transition: 0.4s;
  height: 80px;
  padding: 0.5rem calc((100vw - 1300px) / 2);
}

.pop_out {
  animation-name: hvr-push;
  animation-duration: 0.3s;
  animation-timing-function: linear;

  transition: 0.4s;

  /* background-color: rgba(0, 0, 0, 0.7); */
  background-color: black;
}

.navBar {
  transition: 0.4s linear;
}

.navBar:hover {
  /* background-color: rgba(0, 0, 0, 0.7); */
  background-color: black;
}

.logo {
  transition: 0.5s;
}

.logo:hover {
  animation: blur 0.55s ease-out forwards;
  color: #04d9ff;
  text-shadow: 0 0 32px #fff, 0 0 62px #04d9ff, 0 0 72px #04d9ff,
    0 0 92px #04d9ff, 0 0 131px #04d9ff;
}

.logoLeave {
  transition: 0.5s;
  /* text-shadow: none; */
  animation: blur2 0.55s ease-out forwards;
}

@keyframes blur2 {
  from {
    text-shadow: 0px 0px 7px #04d9ff, 0px 0px 7px #04d9ff, 0px 0px 15px #04d9ff,
      0px 0px 15px #04d9ff, 0px 0px 15px #04d9ff, 0px 0px 15px #04d9ff,
      0px 0px 15px #04d9ff, 0px 0px 15px #04d9ff, 0px 0px 25px #04d9ff,
      0px 0px 25px #04d9ff, 0px 0px 25px #04d9ff, 0px 0px 50px #04d9ff,
      0px 10px 30px #04d9ff, 0px 10px 30px #04d9ff, 0px 10px 30px #04d9ff,
      0px 10px 30px #04d9ff, 0px -10px 30px white, 0px -10px 30px white;
  }
}

@keyframes blur {
  from {
    text-shadow: 0px 0px 7px #04d9ff, 0px 0px 7px #04d9ff, 0px 0px 15px #04d9ff,
      0px 0px 15px #04d9ff, 0px 0px 15px #04d9ff, 0px 0px 15px #04d9ff,
      0px 0px 15px #04d9ff, 0px 0px 15px #04d9ff, 0px 0px 25px #04d9ff,
      0px 0px 25px #04d9ff, 0px 0px 25px #04d9ff, 0px 0px 50px #04d9ff,
      0px 10px 30px #04d9ff, 0px 10px 30px #04d9ff, 0px 10px 30px #04d9ff,
      0px 10px 30px #04d9ff, 0px -10px 30px white, 0px -10px 30px white;
  }
}

/* .logo:hover {
  color: #04d9ff
} */

@media (max-width: 400px) {
  .logo {
    color: #04d9ff !important;
  }
}

.navColorDown {
  color: #04d9ff !important;
  font-weight: bold;
  margin-left: 1rem;
}

.navColorUp {
  color: white;
}

.ml11 {
  font-weight: 700;
  font-size: 3.5em;
}

.aboutTitle {
  /* margin-top: 3rem; */
  /* margin-bottom: 3rem; */
  margin-bottom: -3.3rem;
}

.ml11 .text-wrapper {
  position: relative;
  display: inline-block;
  padding-top: 0.1em;
  padding-right: 0.05em;
  padding-bottom: 0.15em;
}

.ml11 .line {
  opacity: 0;
  position: absolute;
  left: 0;
  height: 100%;
  width: 3px;

  background-color: #04d9ff;
  transform-origin: 0 50%;
}

.ml11 .line1 {
  top: 0;
  left: 0;
}

.ml11 .letter {
  display: inline-block;
  line-height: 1em;
}

.ml6 {
  position: relative;
  font-weight: 900;
  font-size: 3.3em;
}

.ml6 .text-wrapper {
  position: relative;
  display: inline-block;
  padding-top: 0.2em;
  padding-right: 0.05em;
  padding-bottom: 0.1em;
  overflow: hidden;
}

.ml6 .letter {
  display: inline-block;
  line-height: 1em;
}

/* .hvr-underline-reveal {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
  transition: color;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.hvr-underline-reveal:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  background: #04d9ff;
  height: 3px;
  transform: translateY(4px);
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.hvr-underline-reveal:hover:before {
  transform: translateY(0);
}

.hvr-underline-reveal:hover {
  color: #04d9ff;
} */

/* Rotating letters */

.hvr-underline-reveal {
  /* display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
  transition: color;
  transition-duration: 0.3s;
  transition-timing-function: ease-out; */

  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;

  transition: 0.4s;
}
/* .hvr-underline-reveal:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 1.8px;
  background: #04d9ff;
  height: 2px;
  transition-property: right;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
}

.hvr-underline-reveal:hover:before {
  right: 0;
} */

.hvr-underline-reveal:hover {
  /* color: #04d9ff; */
  background-color: rgba(4, 217, 255, 0.7);
}

/* .hvr-overline-from-right {
      display: inline-block;
      vertical-align: middle;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0);
      position: relative;
      overflow: hidden;
    }
    .hvr-overline-from-right:after {
      content: "";
      position: absolute;
      z-index: -1;
      left: 100%;
      right: 0;
      top: 9px;
      background: #04d9ff;
      height: 2px;
      transition-property: left;
      transition-duration: 0.3s;
      transition-timing-function: ease-out;
    }
    .hvr-underline-reveal:hover:after {
      left: 0;
    } */

/* Yaku */
.link--yaku,
.link--yaku2,
.link--yaku3,
.link--yaku4 {
  overflow: hidden;
  font-size: 1.1rem;
}

/* .link--yaku::before {
	content: '';
	position: absolute;
	height: 100%;
	width: 100%;
	border-width: 2px 0;
	border-color: #04d9ff;
	border-style: solid;
	left: 0;
	transform: translate3d(-101%,0,0);
	transition: transform 0.5s;
}

.link--yaku:hover::before {
	transform: translate3d(0,0,0);
} */

.link--yaku span,
.link--yaku2 span,
.link--yaku3 span {
  display: inline-block;
  position: relative;
  transform: perspective(1000px) rotate3d(0, 1, 0, 0deg);
  transition: transform 0.2s, color 0.2s;
}

.link--yaku:hover span,
.link--yaku2:hover span,
.link--yaku3:hover span {
  /* color: #04d9ff; */
  /* color: black; */
  transform: perspective(1000px) rotate3d(0, 1, 0, 360deg);
}

/* .link--yaku span:nth-child(8),
.link--yaku:hover span:first-child {
	transition-delay: 0s;
}

.link--yaku span:nth-child(7),
.link--yaku:hover span:nth-child(2) {
	transition-delay: 0.1s;
}

.link--yaku span:nth-child(6),
.link--yaku:hover span:nth-child(3) {
	transition-delay: 0.2s;
}

.link--yaku span:nth-child(5),
.link--yaku:hover span:nth-child(4) {
	transition-delay: 0.3s;
}

.link--yaku span:nth-child(4),
.link--yaku:hover span:nth-child(5) {
	transition-delay: 0.4s;
}

.link--yaku span:nth-child(3),
.link--yaku:hover span:nth-child(6) {
	transition-delay: 0.5s;
}

.link--yaku span:nth-child(2),
.link--yaku:hover span:nth-child(7) {
	transition-delay: 0.6s;
}

.link--yaku span:nth-child(1),
.link--yaku:hover span:nth-child(8) {
	transition-delay: 0.7s;
} */

/* .link--yaku span:nth-child(4),
.link--yaku:hover span:first-child {
	transition-delay: 0s;
}

.link--yaku span:nth-child(3),
.link--yaku:hover span:nth-child(2) {
	transition-delay: 0.1s;
}

.link--yaku span:nth-child(2),
.link--yaku:hover span:nth-child(3) {
	transition-delay: 0.2s;
}

.link--yaku span:first-child,
.link--yaku:hover span:nth-child(4) {
	transition-delay: 0.3s;
}

.link--yaku span:first-child,
.link--yaku:hover span:nth-child(5) {
	transition-delay: 0.4s;
} */

/* .link--yaku:hover span:first-child {
  transition-delay: 0s;
}
.link--yaku:hover span:nth-child(2) {
  transition-delay: 0.1s;
}
.link--yaku:hover span:nth-child(3) {
  transition-delay: 0.2s;
}
.link--yaku:hover span:nth-child(4) {
  transition-delay: 0.3s;
}

.link--yaku:hover span:nth-child(5) {
  transition-delay: 0.4s;
}

.link--yaku:hover span:nth-child(6) {
  transition-delay: 0.5s;
}

.link--yaku:hover span:nth-child(7) {
  transition-delay: 0.6s;
}
.link--yaku:hover span:nth-child(8) {
  transition-delay: 0.7s;
} */

.link--yaku:hover span:first-child {
  transition-delay: 0s;
}
.link--yaku:hover span:nth-child(2) {
  transition-delay: 50ms;
}
.link--yaku:hover span:nth-child(3) {
  transition-delay: 100ms;
}
.link--yaku:hover span:nth-child(4) {
  transition-delay: 150ms;
}

.link--yaku:hover span:nth-child(5) {
  transition-delay: 200ms;
}

.link--yaku:hover span:nth-child(6) {
  transition-delay: 250ms;
}

.link--yaku:hover span:nth-child(7) {
  transition-delay: 300ms;
}
.link--yaku:hover span:nth-child(8) {
  transition-delay: 350ms;
}

/* .link--yaku2 span:nth-child(4),
.link--yaku2:hover span:first-child {
	transition-delay: 0s;
}

.link--yaku2 span:nth-child(3),
.link--yaku2:hover span:nth-child(2) {
	transition-delay: 0.1s;
}

.link--yaku2 span:nth-child(2),
.link--yaku2:hover span:nth-child(3) {
	transition-delay: 0.2s;
}

.link--yaku2 span:first-child,
.link--yaku2:hover span:nth-child(4) {
	transition-delay: 0.3s;
}

.link--yaku2 span:first-child,
.link--yaku2:hover span:nth-child(5) {
	transition-delay: 0.4s;
}

.link--yaku2 span:first-child,
.link--yaku2:hover span:nth-child(6) {
	transition-delay: 0.5s;
} */

/* .link-yaku:hover span:first-child {
	transition-delay: 0s;
}
.link-yaku:hover span:nth-child(2) {
	transition-delay: 0.1s;
}
.link-yaku:hover span:nth-child(3) {
	transition-delay: 0.2s;
}
.link-yaku:hover span:nth-child(4) {
	transition-delay: 0.3s;
}
.link-yaku:hover span:nth-child(5) {
	transition-delay: 0.4s;
}
.link-yaku:hover span:nth-child(6) {
	transition-delay: 0.5s;
}
.link-yaku:hover span:nth-child(7) {
	transition-delay: 0.6s;
} */

.link--yaku {
  color: #fff;
  text-decoration: none;
  padding: 1rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .link--yaku {
    display: none;
  }
}

/* Squiggli underline */

/* .hvr-underline-reveal {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
  transition: color;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.hvr-underline-reveal:hover {
  color: #04d9ff;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .6s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%2304d9ff' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
  background-position: 0 100%;
  background-size: auto 6px;
  background-repeat: repeat-x;
  text-decoration: none;
} */

.arrowDown {
  margin-left: -1px;
}

.myWork {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

@keyframes hvr-icon-down {
  0%,
  50%,
  100% {
    transform: translateY(0);
  }
  25%,
  75% {
    transform: translateY(6px);
  }
}

.hvr-icon-down .hvr-icon {
  transform: translateZ(0);
}
/* .hvr-icon-down:hover .hvr-icon {
  animation-duration: 0.75s;
  animation-timing-function: ease-out;

  animation-name: hvr-ripple-out, hvr-icon-down;
} */

.hvr-icon:hover {
  animation: hvr-icon-down 1s;
}

/* .hvr-icon-down:before {
  content: "";
  position: absolute;
  border: #04d9ff solid 6px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation-duration: 1.5s;
} */

.hvr-icon-down:before {
  content: "";
  position: absolute;
  /* border: white solid 6px; */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation-duration: 1.5s;
}

.hvr-icon-down:hover:before,
.hvr-icon-down:focus:before,
.hvr-icon-down:active:before {
  animation-name: hvr-ripple-out;
}

@keyframes hvr-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
  }
}

.hvr-radial-in {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
  background: white;
  transition-property: color;
  transition-duration: 0.3s;
}
.hvr-radial-in:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  /* background: white; */
  background: transparent;
  border-radius: 100%;
  transform: scale(2);
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.hvr-radial-in:hover:before {
  transform: scale(0);
}

/* @keyframes hvr-ripple-inHero {
  100% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 50%;
  }
} */

/* .hvr-radial-in {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
} */

/* .hvr-radial-in:after {
  content: "";
  position: absolute;
  border: #04d9ff solid 3px;
  top: 12px;
  right: 12px;
  bottom: 12px;
  left: 12px;
  opacity: 0;
  animation-duration: 0.3s;
  border-radius: 50%;
}
.hvr-radial-in:hover:after,
.hvr-radial-in:focus:after,
.hvr-radial-in:active:after {
  animation-name: hvr-ripple-inHero;
} */

body {
  /* background: white; */
  /* background: #0F1624; */
  background: #040c18;
  color: white;
}

.circle {
  position: absolute;
  border-radius: 50%;

  background: #04d9ff;
  animation: ripple 15s infinite;
  box-shadow: 0px 0px 1px 0px #379683;
}

@media (max-width: 1100px) {
  .circle {
    display: none;
  }
}

.small {
  width: 200px;
  height: 200px;
  left: -100px;
  bottom: -100px;
}

.medium {
  width: 400px;
  height: 400px;
  left: -200px;
  bottom: -200px;
}

.large {
  width: 600px;
  height: 600px;
  left: -300px;
  bottom: -300px;
}

.xlarge {
  width: 800px;
  height: 800px;
  left: -400px;
  bottom: -400px;
}

.xxlarge {
  width: 1000px;
  height: 1000px;
  left: -500px;
  bottom: -500px;
}

.shade1 {
  opacity: 0.2;
}
.shade2 {
  opacity: 0.5;
}

.shade3 {
  opacity: 0.7;
}

.shade4 {
  opacity: 0.8;
}

.shade5 {
  opacity: 0.9;
}

@keyframes ripple {
  0% {
    transform: scale(0.5);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.5);
  }
}

.bg {
  animation: slide 20s ease-in-out infinite alternate;

  background-image: linear-gradient(60deg, white 50%, #04d9ff 50%);

  bottom: 0;
  left: -50%;
  opacity: 0.2;
  position: fixed;
  right: -50%;
  top: 0;
  z-index: -1;
}

.bg2 {
  animation-direction: alternate-reverse;
  animation-duration: 30s;
}

.bg3 {
  animation-duration: 25s;
}

@keyframes slide {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(25%);
  }
}

.cube {
  position: absolute;
  top: 80vh;
  left: 45vw;
  width: 10px;
  height: 10px;
  border: solid 1px #04d9ff;
  transform-origin: top left;
  transform: scale(0) rotate(0deg) translate(-50%, -50%);
  animation: cube 12s ease-in forwards infinite;
  border-color: #04d9ff;
}

@media (max-width: 1600px) {
  .cube {
    display: none;
  }
}

.cube:nth-child(2) {
  animation-delay: 2s;
  left: 25vw;
  top: 40vh;
}
.cube:nth-child(3) {
  animation-delay: 4s;
  left: 75vw;
  top: 50vh;
}
.cube:nth-child(4) {
  animation-delay: 6s;
  left: 90vw;
  top: 10vh;
}
.cube:nth-child(5) {
  animation-delay: 8s;
  left: 10vw;
  top: 85vh;
}
.cube:nth-child(6) {
  animation-delay: 10s;
  left: 50vw;
  top: 10vh;
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes cube {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
  }
  to {
    transform: scale(20) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}

.section {
  padding: 5rem 0;
}

section {
  display: block;
}

.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
}
@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
}

.single-project {
  background: #04d9ff;
  border-radius: 0.5rem;
}

.project-container img {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.project-container {
  position: relative;
}

.project-icon {
  position: absolute;
  font-size: 1.75rem;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 4rem;
  height: 4rem;
  display: grid;
  place-items: center;
  background: #04d9ff;
  border-radius: 50%;
  color: black;
  border: 0.375rem solid hsl(210, 36%, 96%);
}
.project-details {
  padding: 2rem 1.5rem;
}

.project-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.project-footer a {
  color: black;
  text-transform: capitalize;
  transition: all 0.3s linear;
  justify-self: end;
  margin-top: 0.9rem;
}

.projects-page-center {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 3rem 1rem;
}

img:not(.nav-logo) {
  width: 100%;
  display: block;
  /* border: 2px solid rgba(4, 217, 255, 0.7) */
}

.ml13,
.ml14,
.ml6 {
  margin-bottom: 2rem;
}

/* .ml11,
.ml13,
.ml14,
.ml6 {
  margin-bottom: 4rem;
} */

.projectH4 {
  font-size: 0.875rem;
}

@media screen and (min-width: 800px) {
  .projectH4 {
    line-height: 1;
  }
}

@media screen and (min-width: 800px) {
  .projectH4 {
    font-size: 1rem;
  }
}

.projectP {
  margin-top: 1rem;
}

a:hover {
  text-decoration: none;
}

:focus {
  outline: none;
}

.connectWrapper {
  display: flex;
  align-items: center;
  background-size: cover;
  margin-top: 5rem;
}

.inner {
  max-width: 758px;
  margin: auto;

  border: 10px solid #04d9ff;
  padding: 77px 99px 87px;
}

@media (max-width: 380px) {
  .inner {
    padding: 37px 39px 47px;
  }
}

textarea {
  resize: none;
}

.connectH3 {
  text-transform: uppercase;
  font-size: 45px;
  font-family: "Montserrat-Bold";
  text-align: center;
  margin-bottom: 12px;
}

.connectP {
  text-align: center;
  padding: 0 10px;
  margin-bottom: 55px;
  line-height: 1.8;
}

.form-group {
  position: relative;
  display: block;
  margin-bottom: 48px;
}
.form-group span {
  font-size: 15px;
  color: black;
  position: absolute;
  top: 11px;
  transition: all 0.2s ease;
  transform-origin: 0 0;
  cursor: text;
}
.form-group span.border {
  height: 2px;
  display: block;
  position: absolute;
  width: 100%;
  left: 0;
  top: 41px;
  transform: scaleX(0);
  transition: all 0.15s ease;
  background: black;
}

.form-control {
  border: none;
  border-bottom: 2px solid black;
  display: block;
  width: 100%;
  height: 43px;
  font-size: 15px;
  background: none;
  font-family: "Montserrat-SemiBold";
}

.form-control:valid {
  border-bottom: 2px solid black;
}

.form-control:focus + span,
.form-control:valid + span {
  transform: translateY(-22px) scale(0.8);
}

textarea.form-control {
  padding-top: 10px;
  padding-bottom: 10px;
}

.connectB {
  border: none;
  width: 162px;
  height: 51px;
  border: 2px solid black;
  margin: auto;
  margin-top: 60px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: none;
  color: black;
  text-transform: uppercase;
  transform: perspective(1px) translateZ(0);
  position: relative;
  transition-property: color;
  transition-duration: 0.3s;
}
.connectB i {
  margin-left: 10px;
  transform: translateZ(0);
  transition-duration: 0.1s;
  transition-property: transform;
  transition-timing-function: ease-out;
}
.connectB:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #04d9ff;
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.connectB:hover:before {
  transform: scaleX(1);
}
.connectB:hover i {
  transform: translateX(4px);
}

.footer {
  background: rgba(0, 0, 0, 0.4);

  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: 7rem; */
  min-height: 60px;
  color: #04d9ff;
  /* backdrop-filter: blur(1px); */
  font-weight: bold;
}

.footer p {
  /* font-size: clamp(1rem, 4vw, 1.5rem); */
  font-size: 1.1rem;
  text-transform: capitalize;
}

html,
body {
  margin: 0;
  padding: 0;
}

/* html {
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
} */

/* .skillsContainer {
  scroll-snap-align: start;
} */

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.particles-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

canvas {
  display: block;
  /* background: rgb(33,36,50); */
  position: fixed;
  /* z-index: -1; */
}

/* Sorted portfolio */

/* .portfolis {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 2rem;
  column-gap: 2rem;
} */

.projectsP {
  position: absolute;
  top: 1.3rem;
  left: 1.5rem;
  font-size: 1.4rem;
  text-decoration: underline;
  text-decoration-color: rgba(4, 217, 255, 1);
}

@media screen and (min-width: 800px) {
  .projectsP {
    font-size: 1.6rem;
  }
}

/* .projectsP::before {
  content: "";
  position: absolute;
  left: 45%;
  bottom: 0.2px;
  width: 130px;
  height: 8px;
  transform: skew(-12deg) translateX(-50%);
  background: #04d9ff;
  z-index: -1;
} */

.portfolis {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .PortfolioPage {
    scroll-margin: 6.5rem;
  }
}

@media screen and (max-width: 1600px) {
  .portfolis {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 900px) {
  .portfolis {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* @media screen and (max-width: 330px) {
  .portfolis {
    margin-left: -0.9rem;
  }
} */

@media screen and (max-width: 280px) {
  .main-content {
    margin-left: -1.3rem !important;
  }
}

.projectDesc {
  margin-left: 25px;
  margin-bottom: 5px;
  font-size: 17px;
}

@media screen and (max-width: 1000px) {
  .projectDesc {
    /* margin-left: 15px; */
    margin-left: 6px;
  }
}

.image-data {
  position: relative;
  /* border-radius: 24px; */
  /* box-shadow: 0px 0px 9px 1px #04d9ff; */

  position: relative;
  /* border: #04d9ff solid 3px; */
  transition: 0.7s;

  margin-top: 3rem;
  /* width: 45rem; */
  text-align: center;
}

@media screen and (min-width: 1300px) {
  .image-data {
    width: 45rem;
  }
}

/* @media screen and (max-width: 400px) {
  .image-data {
    width: 16rem;
  }
} */

@media screen and (max-width: 400px) {
  .image-data {
    width: 19rem;
  }
}

@media screen and (max-width: 320px) {
  .image-data {
    width: 16rem;
  }
}

.image-data::before {
  content: "";
  position: absolute;
  top: 12px;
  left: 14px;
  height: calc(100% - 25px);
  width: calc(100% - 25px);
  /* background-color: rgba(255, 255, 255, 0.925); */
  /* background: radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  ); */
  background: rgba(4, 44, 84, 0.8);
  transform-origin: center;
  transform: scale(0);
  transition: all 0.4s ease-in-out;
  /* border-radius: 20px; */

  z-index: 1;
}

.image-data:hover::before {
  transform: scale(1);
}

/* .image-data:after {
  content: '';
  position: absolute;
  border: #04d9ff solid 1px;
  border-radius: 20px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation-duration: 0.7s;
  animation-fill-mode: forwards;
}

.image-data:hover:after, .image-data:focus:after, .image-data:active:after {
  animation-name: imgDataRipple;
}

@keyframes imgDataRipple {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
} */

.portImage {
  width: 100%;
  /* height: 30vh; */

  height: 26.7vh;
/* 
  height: 32vh; */

  object-fit: cover;
  /* border-radius: 20px; */
}

@media screen and (max-width: 500px) {
  .portImage {
    height: 32vh;
  }
}

@media screen and (max-height: 420px) {
  .portImage {
    height: 15rem;
  }
}

.hover-items {
  list-style: none;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem 2rem;
  visibility: hidden;

  z-index: 2;
}

.hover-items2 {
  z-index: 3;
}

.hover-items2 {
  list-style: none;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  width: 70%;
  opacity: 0;


}

.portA {
  padding: 1rem;
  text-decoration: none;
  font-family: inherit;
  /* border-radius: 10px; */
  background-color: #037fff;
  color: white;
}

.portA {
  padding: 1rem;
  text-decoration: none;
  font-family: inherit;
  /* border-radius: 10px; */
  background-color: #037fff;
  color: white;
}
.portA:not(:last-child) {
  margin-right: 2rem;
}

.portH5 {
  font-size: 1.7rem;
  font-weight: 400;
  /* color: white; */
  transition: all 0.3s ease-in-out;
}
.portH5:hover {
  color: #037fff;
  cursor: pointer;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 1rem 10rem; */
  flex-wrap: wrap;
}
/* @media screen and (max-width: 1270px) {
  .buttons {
    margin: 2rem 5rem;
  }
} */
@media screen and (max-width: 1100px) {
  .buttons {
    margin-top: 0;
    margin-left: 1rem;
  }
}

.main-content {
  width: 100%;
  min-height: 100vh;
  display: grid;
  position: relative;
  padding-right: 1rem;
  padding-left: 1rem;
}

.content {
  margin: 4rem 15rem;
}

@media screen and (max-width: 1100px) {
  .content {
    margin: 2rem 10rem;
  }
}

@media screen and (max-width: 1400px) {
  .content {
    margin: 3rem 10rem;
  }
}

@media screen and (max-width: 710px) {
  .content {
    margin: 2rem 1rem;
  }
}

@media screen and (max-width: 1000px) {
  .main-content {
    margin-left: 0;
    width: 100%;
  }
}

/* .btn-port {
  padding: 0.6rem 2rem;
  background-color: #04d9ff;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  color: white;
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out;
  margin-right: 1rem;

  transition: all 0.3s;
  position: relative;
  border: 2px solid rgba(4, 217, 255, 1);
} */

.btn-port {
  padding: 0.6rem 2rem;
  /* background-color: #04d9ff; */
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  color: white;
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out;
  margin-right: 1rem;

  transition: all 0.3s;
  position: relative;
  border: 2px solid #04d9ff;
}

/* .btn-port:hover{
  color: #04d9ff;
} */

.btn-port::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  /* background-color: rgba(255,255,255,0.1); */
  transition: all 0.3s;
}
.btn-port:hover::before {
  opacity: 0;
  transform: scale(0.5, 0.5);
}
.btn-port::after {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  /* border: 1px solid rgba(255,255,255,0.5); */
  border: 4px solid #04d9ff;
  transform: scale(1.2, 1.2);
}
.btn-port:hover::after {
  opacity: 1;
  transform: scale(1, 1);
}

/* .btn-port:hover {
  background: transparent;
} */

/* .btn-port:hover {
  background-color: #037fff;
  animation: pulse 1.5s;
}


@keyframes pulse {
  0% { box-shadow: 0px 0px 30px 0px salmon; }
} */

/* @keyframes hvr-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}

.btn-port {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  position: relative;
}

.btn-port:before {
  content: '';
  position: absolute;
  border: #0381ff5b solid 6px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation-duration: 1s;
  animation-fill-mode: forwards
} */

.btn-port:active {
  /* background-color: #037fff; */
  /* background: transparent;
  border: 2px solid rgba(4,217,255,1); */
}
.btn-port:focus {
  /* background-color: #037fff; */
  background: transparent;
  border: 2px solid #04d9ff;
  background-color: #04d9ff;
}

.activeBtn {
  /* background-color: #037fff; */
  background: transparent;
  border: 2px solid #04d9ff;
  background-color: #04d9ff;
}

/* ::-webkit-scrollbar {
  width: 12px;
  transition: 0.5s;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(transparent, #04d9ff);
  border-radius: 6px;
}

::-webkit-scrollbar:hover {
  background: #04d9ff;
} */

/* .container::-webkit-scrollbar {
  width: 14px;
}
.container::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 7px;
  box-shadow: inset 0 0 0 10px;
}
.container::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.container::-webkit-scrollbar-corner {
  background-color: transparent;
} */
.container::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 7px;
  box-shadow: inset 0 0 0 10px;
}
.container::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.container::-webkit-scrollbar-corner {
  background-color: transparent;
}
*/ .container {
  overflow: auto;
  color: #00000000;
  transition: color 1s;
}
.container:hover {
  color: #666666ff;
}

.portfolis .portfolio .image-data .hover-items li a {
  padding: 1rem;
  text-decoration: none;
  font-family: inherit;
  /* border-radius: 10px; */
  background-color: #04d9ff;
  color: white;
}

.portfolis .portfolio .image-data .hover-items2 li a {
  padding: 1rem;
  text-decoration: none;
  font-family: inherit;
  border-radius: 10px;
  background-color: #04d9ff;
  color: white;
}

.portfolis .portfolio .image-data .hover-items li a:not(:last-child) {
  margin-right: 2rem;
}

.portfolis .portfolio .image-data .hover-items2 li a:not(:last-child) {
  margin-right: 2rem;
}

.portfolis .portfolio .image-data:hover .hover-items {
  transition: all 0.5s ease-in-out 0.2s;
  visibility: visible;
  top: 30%;
}

.portfolis .portfolio .image-data:hover .hover-items2 {
  transition: all 0.5s ease-in-out 0.2s;
  /* visibility: visible; */
  opacity: 1;
  /* left: 50%; */
}

.portfolis .portfolio h5 {
  font-size: 1.7rem;
  font-weight: 400;
  color: white;
  transition: all 0.3s ease-in-out;
}
.portfolis .portfolio h5:hover {
  color: #037fff;
  cursor: pointer;
}

.portLi {
  display: flex;
}

.portA {
  transition: color 0.3s ease;
  /* transition: all 0.5s ease-in-out; */

  position: relative;
  /* transform: perspective(1px) translateZ(0); */
}

.portA:before {
  content: "";
  position: absolute;
  border: #04d9ff solid 6px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation-duration: 1.35s;
  /* border-radius: 10px; */
}

.portA:hover:before,
.portA:focus:before,
.portA:active:before {
  animation-name: portAripple;
  animation-iteration-count: infinite;
}

@keyframes portAripple {
  100% {
    top: -20px;
    right: -20px;
    bottom: -20px;
    left: -20px;
    opacity: 0;
  }
}

.portA:after {
  content: "";
  position: absolute;
  border: #04d9ff solid 6px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation-duration: 1.35s;
  /* border-radius: 10px; */
  animation-delay: 0.25s;
}

.portA:hover:after,
.portA:focus:after,
.portA:active:after {
  animation-name: portAripple2;
  animation-iteration-count: infinite;
}

@keyframes portAripple2 {
  100% {
    top: -23px;
    right: -23px;
    bottom: -23px;
    left: -23px;
    opacity: 0;
  }
}

/* @keyframes hvr-ripple-inProject {
  100% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
  }
}
.portA:after {
  content: '';
  position: absolute;
  border: #04d9ff solid 6px;
  top: -12px;
  right: -12px;
  bottom: -12px;
  left: -12px;
  opacity: 0;
  animation-duration: 1.1s;
  border-radius: 10px;
}
.portA:hover:after, .portA:focus:after, .portA:active:after {
  animation-name: hvr-ripple-inProject;
  animation-iteration-count: infinite;
  animation-delay: 0.2s;
} */

.portA:hover {
  /* color:   #042c54; */
  /* color: radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%) !important; */
  color: rgba(27, 120, 222, 1) !important;
}

.scroll-btn {
  position: fixed;
  right: 3rem;
  bottom: 1.5rem;
  background: none;
  color: #fff;
  z-index: -100;
  opacity: 0;
  transition: 0.5s;
  font-size: 2rem;
  line-height: 0;
  padding: 0.2rem 0.2rem;
  cursor: pointer;
  /* border-radius: 50%; */
  /* border: 3px solid #04d9ff; */
  outline: none;
  transform: perspective(1px) translateZ(0);

  border: none;
}

.scroll-btn:hover {
  color: #04d9ff;
  /* background: transparent; */
}

.show-scroll-btn {
  z-index: 100;
  opacity: 1;
}
/* .scroll-btn:before {
  content: "";
  position: absolute;
  border: 3px solid #04d9ff;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation-duration: 1s;
  border-radius: 50%;
}

.scroll-btn:hover:before {
  animation-name: hvr-ripple-out2;
}
@keyframes hvr-ripple-out2 {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}  */

/* .scroll-btn:before {
  content: "";
  position: absolute;
  border: 3px solid #04d9ff;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation-duration: 1s;
  border-radius: 50%;
}

.scroll-btn:hover:before {
  animation-name: hvr-ripple-out2;
}
@keyframes hvr-ripple-out2 {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
} */

/* .scroll-btn:hover:before {
  animation-name: hvr-ripple-out2;
}


/* New skills */

.services .service-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 50px;
}
.services .service-item {
  /* flex-basis: 80%; */
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  border-radius: 10px;
  background-image: url(./images/img-16.png);
  background-size: cover;
  margin: 10px 5%;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.services .service-item::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
  opacity: 0.9;
  z-index: -1;
}
.services .service-bottom .icon {
  height: 80px;
  width: 80px;
  margin-bottom: 20px;
}
.services .service-item h2 {
  font-size: 2rem;
  color: white;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.services .service-item p {
  color: white;
  text-align: left;
}

/* End Services Section */

/* .active {
  color: #fcfcfc;
} */

/* New about photo effect */

/* General */

.grid {
  position: relative;
  /* clear: both; */
  /* margin: 0 auto; */
  /* padding: 1em 0 4em; */
  /* max-width: 1000px; */
  list-style: none;
  text-align: center;
}

/* Common style */
.grid figure {
  position: relative;
  float: left;
  overflow: hidden;
  /* margin: 10px 1%; */
  /* min-width: 320px; */
  /* max-width: 480px; */
  /* max-height: 360px; */
  /* width: 48%; */
  /* height: auto; */
  /* background: #3085a3; */
  text-align: center;
  cursor: pointer;
  border-radius: 7px;

  /* height: 460px; */
}

@media screen and (min-width: 1100px) {
  .effect-apollo {
    height: 460px;
    width: 400px;
  }
}

.grid figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}

.grid figure figcaption {
  padding: 2em;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
  pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.grid figure h2 {
  word-spacing: -0.15em;
  font-weight: 300;
}

.grid figure h2 span {
  font-weight: 800;
}

.grid figure h2,
.grid figure p {
  margin: 0;
}

.grid figure p {
  letter-spacing: 1px;
  font-size: 68.5%;
}

/*-----------------*/
/***** Apollo *****/
/*-----------------*/

figure.effect-apollo {
  background: #3498db;

  /* width: 20rem; */
  /* max-height: 23.4rem; */
  max-height: 373px;
  margin-top: 3px;

  width: 31rem;
}

figure.effect-apollo img {
  opacity: 0.95;
  transition: opacity 0.35s, transform 0.35s;
  transform: scale3d(1.05, 1.05, 1);
}

figure.effect-apollo figcaption::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  content: "";
  transition: transform 0.6s;
  transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg)
    translate3d(0, -100%, 0);
}

figure.effect-apollo p {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 3em;
  padding: 0 1em;
  max-width: 150px;
  border-right: 4px solid #fff;
  text-align: right;
  opacity: 0;
  transition: opacity 0.35s;
}

figure.effect-apollo h2 {
  text-align: left;
}

figure.effect-apollo:hover img {
  opacity: 0.6;
  transform: scale3d(1, 1, 1);
}

figure.effect-apollo:hover figcaption::before {
  transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg)
    translate3d(0, 100%, 0);
}

figure.effect-apollo:hover p {
  opacity: 1;
  transition-delay: 0.1s;
}

.aboutTitle {
  color: #04d9ff;
  /* transition: 0.4s; */
}

figure.effect-apollo:hover .aboutTitle {
  color: #fff;
}

/* New skills component */

/* .swiper-container {
  width: 100%;
  height: 90%;
  border-radius: 50px;
} */

/* .swiper-container {
  margin-top: 2.5rem;
} */

@media (max-width: 800px) {
  .swiper-container {
    margin-top: -2rem;
  }
}

.swiper-slide {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-general {
  padding: 75px 0;
  margin: 0 auto;
  width: 1070px;
  z-index: 100;
}

@media (max-height: 400px) {
  .container-general {
    margin-top: -1.3rem;
  }
}

@media (max-height: 420px) {
  .skillsTitle {
    display: none;
  }
}

.container-general .gallery-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60vh;
}
.container-general .gallery-wrap .item {
  flex: 1;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: none;
  transition: all 0.8s ease;

  display: flex;
  justify-content: center;
  align-items: center;
}

.firstSkills {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.lastSkills {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* @media (max-width: 800px) {
  .lastSkills,
  .lastGrid {
    display: none !important;
  }
} */

.skillsParagraph {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .skillsP {
  margin-left: 3rem;
} */

.item {
  transition: 1s;
  border-radius: 0px !important;
}

.container-general .gallery-wrap .item:hover {
  flex: 7;
  /* opacity: 0.5;
  background-color: black; */
  /* filter: brightness(90%); */
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)), 
  url(./images/skills-4.png);
  transition: 1s; */
}

.container-general .gallery-wrap .item:before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background-image: linear-gradient(
    to bottom,
    hsl(344, 100%, 50%),
    hsl(31, 100%, 40%)
  ); */
  background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5));
  transition: 1s;
  /* z-index: -1; */
  transition: opacity 0.5s linear;
  opacity: 0;
}

.container-general .gallery-wrap .item:hover:before {
  opacity: 1;
}

/* .wrap-effect-1 .item:first-of-type {
  background-image: url("https://cdn.dribbble.com/users/729829/screenshots/4185141/galshir-cactus-coffee.png");
}
.wrap-effect-1 .item:nth-of-type(2) {
  background-image: url("https://cdn.dribbble.com/users/729829/screenshots/6146136/galshir-tea-biscuit_2x.png");
}
.wrap-effect-1 .item:nth-of-type(3) {
  background-image: url("https://cdn.dribbble.com/users/729829/screenshots/4473482/galshir-dog-walker.gif");
}
.wrap-effect-1 .item:nth-of-type(4) {
  background-image: url("https://cdn.dribbble.com/users/729829/screenshots/5743606/gal-shir-scooter_2x.png");
}
.wrap-effect-1 .item:last-of-type {
  background-image: url("https://cdn.dribbble.com/users/729829/screenshots/4738104/galshir-storytime.png");
} */

.firstSkills {
  /* background-image: url("https://cdn.dribbble.com/users/729829/screenshots/4185141/galshir-cactus-coffee.png"); */
  /* background-image: url(./images/skills-4.png); */
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)),
    url(./images/skills-4.png);
  border-top-left-radius: 9px !important;
  border-bottom-left-radius: 9px !important;
}

.item2 {
  /* background-image: url("https://cdn.dribbble.com/users/729829/screenshots/6146136/galshir-tea-biscuit_2x.png"); */
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(./images/skills-3.jpg);
}

.item3 {
  /* background-image: url("https://cdn.dribbble.com/users/729829/screenshots/4473482/galshir-dog-walker.gif"); */
  background-image: url(./images/skills-5.jpg);
}

.item4 {
  /* background-image: url("https://cdn.dribbble.com/users/729829/screenshots/5743606/gal-shir-scooter_2x.png"); */
  background-image: url(./images/skills-6.png);
}

.lastSkills {
  /* background-image: url("https://cdn.dribbble.com/users/729829/screenshots/4738104/galshir-storytime.png"); */
  background-image: url(./images/skills-8.jpg);
  border-bottom-right-radius: 9px !important;
  border-top-right-radius: 9px !important;
}

/* Additional effects for skills */

/*---------------*/
/***** Roxy *****/
/*---------------*/

/* figure.effect-roxy {
	background: linear-gradient(45deg, #ff89e9 0%,#05abe0 100%);
} */

/* figure.effect-roxy img {
	max-width: none;
	width: -webkit-calc(100% + 60px);
	width: calc(100% + 60px);
	transition: opacity 0.35s, transform 0.35s;
	transform: translate3d(-50px,0,0);
} */

/* figure.effect-roxy figcaption::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 3px solid #04d9ff;
  content: "";
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translate3d(-20px, 0, 0);
  border-radius: 10px;
} */

figure.effect-roxy figcaption {
  /* padding: 3em; */
  text-align: left;

  display: flex;
  justify-content: center;
  align-items: center;
}

/* figure.effect-roxy h2 {
  padding: 30% 0 10px 0;
} */

figure.effect-roxy p {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translate3d(-10px, 0, 0);
  text-align: center;
  color: white;

  font-size: 15px;
  font-weight: bold;
}

/* figure.effect-roxy:hover img {
	opacity: 0.7;
	transform: translate3d(0,0,0);
} */

@media (max-width: 1000px) {
  figure.effect-roxy p {
    font-size: 11px;
  }
}

figure.effect-roxy:hover figcaption::before,
figure.effect-roxy:hover p {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

/* Hero slider */

/* .slide-nav-button {
  border-color: blue;
} */

/* Nav links active */

/* .is-active {
  background-color: blue;
  color: salmon;
}

.nav ul li a.is-active {
  background: darkslategrey;
} */

.active {
  /* color: #04d9ff; */

  color: white;
  /* background-color: #04d9ff; */
  background-color: rgba(4, 217, 255, 0.7);
}

/* .active {
  transition: 0.3s;
}

.active span {
  color: #04d9ff !important;
} */

/* Contact me  margin */

/* .contactMe {
  margin-top: 6rem;
} */

/* .contactMe {
  scroll-margin: -2.5rem;
} */

@media screen and (max-width: 1000px) {
  .contactMe {
    scroll-margin: 3.5rem;
  }
}

/* Hero header animation */

/* .word {
	font-family: 'Anton', sans-serif;
	perspective: 1000px; 
} */

.card:hover {
  animation-name: hvr-wobble-skew;
}

.word span {
  cursor: pointer;
  display: inline-block;
  /* font-size: 100px; */
  user-select: none;
  /* line-height: .8; */
  transition: 0.8s;
}

.word span:hover {
  color: #04d9ff;
}

.word span:nth-child(1).active {
  animation: falling 0.5s ease-out;
  /* transform-origin: bottom left; */
  transform-origin: bottom center;
}

@keyframes falling {
  12% {
    transform: rotateX(240deg);
  }

  24% {
    transform: rotateX(150deg);
  }

  36% {
    transform: rotateX(200deg);
  }

  48% {
    transform: rotateX(175deg);
  }

  60%,
  85% {
    transform: rotateX(180deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}

/* @keyframes balance {
	0%, 100% {
		transform: rotate(0deg);
	}
	
	30%, 60% {
		transform: rotate(-360deg);
	}
} */

/* @keyframes balance {
	0%, 100% {
		transform: rotate(0deg);
	}
	
	30%, 60% {
		transform: rotate(360deg);
	}
} */

/* @keyframes balance {
  0% {
    transform: rotate(0deg) rotateX(0deg) scale(1.5);
  }

  100% {
    transform: rotate(360deg) rotateX(360deg) scale(1);
  }
} */

/* @keyframes balance {
  30% {
    transform: scaleY(0.1) 
  }

  80% {
    transform: scaleY(3) 
  }

  100% {
    transform: scaleY(1) 
  }
} */

/* @keyframes balance {
  30% {
    transform: scaleY(0.3)
  }

  80% {
    transform: scaleY(3)
  }

  100% {
    transform: scaleY(1)
  }
} */

@keyframes balance {
  12% {
    transform: rotateX(240deg);
  }

  24% {
    transform: rotateX(150deg);
  }

  36% {
    transform: rotateX(200deg);
  }

  48% {
    transform: rotateX(175deg);
  }

  60%,
  85% {
    transform: rotateX(180deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}

.word span:nth-child(2).active {
  animation: shrinkjump 1.2s ease-in-out;
  transform-origin: bottom center;
}

@keyframes shrinkjump {
  10%,
  35% {
    transform: scale(2, 0.2) translate(0, 0);
  }

  45%,
  50% {
    transform: scale(1) translate(0, -150px);
  }

  80% {
    transform: scale(4, 0.2);
  }

  100% {
    transform: scale(1) translate(0, 0);
  }
}

.word span:nth-child(4).active {
  animation: rotate 120ms ease-out;
  animation-iteration-count: 5;
}

@keyframes rotate {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

.word span:nth-child(3).active {
  animation: toplong 1s linear;
}

@keyframes toplong {
  10%,
  40% {
    transform: translateY(-20vh) scaleY(1) rotate(0deg);
  }

  90% {
    transform: translateY(-20vh) scaleY(1.5) rotate(1440deg);
  }
}

/* @keyframes toplong {
  10%,
  40% {
    transform: translateY(-20vh) scaleY(1) rotate(0deg);
  }

  90% {
    transform: translateY(-20vh) scaleY(2) rotate(360deg);
  }
} */

/* @keyframes toplong {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
} */

/* About text effect */

.aaboutLettersHover {
  animation: aboutText 1s linear;
}

@keyframes aboutText {
  0% {
    color: blue;
    background-color: blue;
    /* transform: scale(2) */
  }
  100% {
    color: white;
    background-color: white;
    /* transform: scale(1) */
  }
}

/* New contact form */

.connectForm {
  background-color: #15172b;
  border-radius: 20px;
  box-sizing: border-box;
  height: 700px;
  padding: 20px;
  width: 400px;
}

@media screen and (max-width: 900px) {
  .connectForm {
    width: 330px;
  }
}

@media screen and (max-width: 280px) {
  .connectForm {
    width: 270px;
  }
}

.title {
  color: #eee;
  font-family: sans-serif;
  font-size: 36px;
  font-weight: 600;
  margin-top: 30px;
}

.subtitle {
  color: #eee;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}

.input-container {
  height: 50px;
  position: relative;
  width: 100%;
}

.ic1 {
  margin-top: 14px;
}

.ic2 {
  margin-top: 30px;
}

.ic3 {
  height: 300px;
}

.input {
  background-color: #303245;
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  font-size: 18px;
  height: 100%;
  outline: 0;
  padding: 4px 20px 0;
  width: 100%;
}

.cut {
  background-color: #15172b;
  border-radius: 10px;
  height: 20px;
  left: 20px;
  position: absolute;
  top: -20px;
  transform: translateY(0);
  transition: transform 200ms;
  width: 76px;
}

.cut-short {
  width: 50px;
}

.input:focus ~ .cut,
.input:not(:placeholder-shown) ~ .cut {
  transform: translateY(8px);
}

.placeholder {
  color: #65657b;
  font-family: sans-serif;
  left: 20px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  top: 20px;
}

.input:focus ~ .placeholder,
.input:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-30px) translateX(10px) scale(0.75);
}

.input:not(:placeholder-shown) ~ .placeholder {
  color: #808097;
}

.input:focus ~ .placeholder {
  color: #dc2f55;
}

.submit {
  background-color: #08d;
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  cursor: pointer;
  font-size: 18px;
  height: 50px;
  margin-top: 38px;
  text-align: center;
  width: 100%;
}

.submit:active {
  background-color: #06b;
}

/* About text effect */

.jump {
  margin-bottom: 3rem;
}

.jump span {
  position: relative;
  display: inline-block;
  transition: transform 0.2s;
  cursor: url("http://www.worldofbuzz.com/wp-content/uploads/2016/11/rocket-2.png"),
    default;
}
.jump span:hover {
  transform: translateY(-10px) scale(1.5) rotate(20deg);
  z-index: 1;
}

.jump span {
  position: relative;
  display: inline-block;
  transition: transform 0.2s;

  margin: 0.8px;
}

/* .jump2 span:hover {
  transform: translateY(-10px) scale(1.5) rotate(20deg);
  z-index: 1;
} */

.aboutParagraphs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.aboutParagraphs p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* CV button */

/* .aboutButton {
  font-size: 1.5rem;
  border: 2px solid white;
  border-radius: 100px;
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 10% auto;
  transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.icon {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}
.aboutButton:hover {
  width: 125px;
  background-color: white;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  color: blue;
  transition: 0.3s;
} */
/* .aboutButton:active {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  transition: 0.05s;
} */

.btn-flip {
  opacity: 1;
  outline: 0;
  color: #fff;
  position: relative;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  font-family: "Open Sans";
  margin-top: 6rem;

  /* line-height: 45px; */
}
.btn-flip:hover:after {
  opacity: 1;
  transform: translateY(0) rotateX(0);
}
.btn-flip:hover:before {
  opacity: 0;
  transform: translateY(50%) rotateX(90deg);
}
.btn-flip:after {
  top: 0;
  left: 0;
  opacity: 0;
  width: 95%;
  color: #04d9ff;
  display: block;
  transition: 0.5s;
  position: absolute;
  background: transparent;
  content: attr(data-back);
  transform: translateY(-50%) rotateX(90deg);
  border-radius: 5px;
  border: 3px solid white;
  font-size: 20px;
  line-height: 45px;
  /* width: 86px; */

  background: white;
}
.btn-flip:before {
  top: 0;
  left: 0;
  opacity: 1;
  color: white;
  display: block;
  padding: 0 30px;
  line-height: 40px;
  transition: 0.5s;
  position: relative;
  background: transparent;
  content: attr(data-front);
  transform: translateY(0) rotateX(0);
  border-radius: 5px;
  border: 3px solid #04d9ff;
  line-height: 45px;
  font-size: 20px;
  /* width: 89px */

  background: #04d9ff;
}

@media screen and (max-width: 1200px) {
  .btn-flip {
    margin-bottom: 3rem;
    margin-top: 3rem;
  }
}

/* About update */

/* .aboutSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: lightsalmon;
  height: 100vh;
} */

.ml9 {
  position: relative;
  font-weight: 200;
  font-size: 4em;
}

.ml9 .text-wrapper {
  position: relative;
  display: inline-block;
  padding-top: 0.2em;
  padding-right: 0.05em;
  padding-bottom: 0.1em;
  overflow: hidden;
}

.ml9 .letter {
  transform-origin: 50% 100%;
  display: inline-block;
  line-height: 1em;
}

/* Hero slider buttons */

/* Hero component slides buttons */

.slide-nav-button {
  transition: 0.7s !important;
  border-bottom: 2px solid #fff !important;

  /* height: 25px !important; */
  /* max-width: 60px; */
  /* min-width: 25px !important; */
}

.slide-nav-button:hover {
  border-color: #04d9ff !important;
  animation: heroSlides 0.4s forwards;
}

/* @keyframes heroSlides {
  100% {
    transform: rotate(-360deg);
  }
} */

/* .removeSlideAni {
  transition: 0.6s !important;
  border-bottom: 8px solid #fff !important;
}

.removeSlideAni:hover {
  border-color: #04d9ff !important;
  animation: heroSlides2 0.4s forwards;
}

@keyframes heroSlides2 {
  100% {
    transform: rotate(360deg);
  }
} */

.slide-nav-button:active {
  border-color: #04d9ff !important;
}

.slide-nav-active-button {
  border-color: #04d9ff !important;
}

/* About information new */

.connect {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* @media screen and (max-width: 1000px) {
  .connect {
    flex-direction: column;
  }
} */

.contactInfo {
  margin-left: 5rem;
  /* margin-top: 13rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* @media screen and (max-width: 1000px) {
  .contactInfo {
    margin-left: 0;
    margin-top: 5rem;
  }
} */

.contactSpan {
  margin-bottom: 3rem;
  font-size: clamp(1.3rem, 4vw, 1.5rem);
}

.contactMe {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.connectSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1000px) {
  .connectSection {
    flex-direction: column;
  }
}

/* Hero text animations */

/* .animate {
	font-size: 50px;
	margin: 100px 0 0;
	border-bottom: 2px solid #ccc;
} */

.animate span {
  display: inline-block;
  cursor: pointer;
}

.animate span:nth-of-type(2) {
  animation-delay: 0.05s;
}
.animate span:nth-of-type(3) {
  animation-delay: 0.1s;
}
.animate span:nth-of-type(4) {
  animation-delay: 0.15s;
}
.animate span:nth-of-type(5) {
  animation-delay: 0.2s;
}
.animate span:nth-of-type(6) {
  animation-delay: 0.25s;
}
.animate span:nth-of-type(7) {
  animation-delay: 0.3s;
}
.animate span:nth-of-type(8) {
  animation-delay: 0.35s;
}
/* .animate span:nth-of-type(9) {
	animation-delay: .4s;
}
.animate span:nth-of-type(10) {
	animation-delay: .45s;
}
.animate span:nth-of-type(11) {
	animation-delay: .5s;
}
.animate span:nth-of-type(12) {
	animation-delay: .55s;
}
.animate span:nth-of-type(13) {
	animation-delay: .6s;
}
.animate span:nth-of-type(14) {
	animation-delay: .65s;
}
.animate span:nth-of-type(15) {
	animation-delay: .7s;
}
.animate span:nth-of-type(16) {
	animation-delay: .75s;
}
.animate span:nth-of-type(17) {
	animation-delay: .8s;
}
.animate span:nth-of-type(18) {
	animation-delay: .85s;
}
.animate span:nth-of-type(19) {
	animation-delay: .9s;
}
.animate span:nth-of-type(20) {
	animation-delay: .95s;
} */

/* Animation One */

/* .one span {
	color: #24a8e6;
	opacity: 0;
	transform: translate(-150px, -50px) rotate(-180deg) scale(3);
	animation: revolveScale 0.2s  forwards;
}

@keyframes revolveScale {
	60% {
		transform: translate(20px, 20px) rotate(30deg) scale(.3);
	}

	100% {
		transform: translate(0) rotate(0) scale(1);
		opacity: 1;
	}
}

.oneHover span {
  color: #24a8e6;
	opacity: 0;
  transform: translate(-150px, -50px) rotate(-180deg) scale(3);
  animation: revolveScale2 0.2s  forwards;
}

@keyframes revolveScale2 {
	60% {
		transform: translate(20px, 20px) rotate(30deg) scale(.3);
	}

	100% {
		transform: translate(0) rotate(0) scale(1);
		opacity: 1;
	}
} */

/* Animation Seven */

.one span {
  color: white;
  opacity: 0;
  transform: translate(-150px, 0) scale(0.3);
  animation: leftRight 0.5s forwards;
}

@keyframes leftRight {
  40% {
    transform: translate(50px, 0) scale(0.7);
    opacity: 1;
    color: white;
  }

  60% {
    color: #04d9ff;
  }

  80% {
    transform: translate(0) scale(2);
    opacity: 0;
  }

  100% {
    transform: translate(0) scale(1);
    opacity: 1;
  }
}

.oneHover span {
  color: #04d9ff;
  opacity: 0;
  transform: translate(-150px, 0) scale(0.3);
  animation: leftRight2 0.5s forwards;
}

@keyframes leftRight2 {
  40% {
    transform: translate(50px, 0) scale(0.7);
    opacity: 1;
    color: white;
  }

  60% {
    color: #04d9ff;
  }

  80% {
    transform: translate(0) scale(2);
    opacity: 0;
  }

  100% {
    transform: translate(0) scale(1);
    opacity: 1;
  }
}

@keyframes leftRight2 {
  40% {
    transform: translate(50px, 0) scale(0.7);
    opacity: 1;
    color: white;
  }

  60% {
    color: #04d9ff;
  }

  80% {
    transform: translate(0) scale(2);
    opacity: 0;
  }

  100% {
    transform: translate(0) scale(1);
    opacity: 1;
  }
}

/* Header alternative animations */

/* Animation Three */

.three span {
  /* color: #b10e81; */
  opacity: 0;
  transform: translate(-300px, 0) scale(0);
  animation: sideSlide 0.5s forwards;
}

/* @keyframes sideSlide {
	60% {
		transform: translate(20px, 0) scale(1);
		color: #b10e81;
	}

	80% {
		transform: translate(20px, 0) scale(1);
		color: #b10e81;
	}

	99% {
		transform: translate(0) scale(1.2);
		color: #00f0ff;
	}

	100% {
		transform: translate(0) scale(1);
		opacity: 1;
		color: #b10e81;
	}
} */

.threeHover span {
  /* color: #04d9ff; */
  opacity: 0;
  /* transform: translate(-150px, -50px) rotate(-180deg) scale(3); */
  transform: translate(-150px, 0) scale(0.3);
  animation: sideSlide2 0.5s forwards;
}

/* @keyframes sideSlide {
	40% {
		transform: translate(50px, 0) scale(.7);
		opacity: 1;
		color: white;
	}

	60% {
		color: #04d9ff;
	}

	80% {
		transform: translate(0) scale(2);
		opacity: 0;
	}

	100% {
		transform: translate(0) scale(1);
		opacity: 1;
	}
}  */

@keyframes sideSlide {
  60% {
    transform: translate(20px, 0) scale(1);
    color: white;
  }

  80% {
    transform: translate(20px, 0) scale(1);
    color: white;
  }

  99% {
    transform: translate(0) scale(1.2);
    color: #04d9ff;
  }

  100% {
    transform: translate(0) scale(1);
    opacity: 1;
    color: white;
  }
}

@keyframes sideSlide2 {
  60% {
    transform: translate(20px, 0) scale(1);
    color: white;
  }

  80% {
    transform: translate(20px, 0) scale(1);
    color: white;
  }

  99% {
    transform: translate(0) scale(1.2);
    color: #04d9ff;
  }

  100% {
    transform: translate(0) scale(1);
    opacity: 1;
    /* color: white; */
    color: #04d9ff;
  }
}

/* Additional hero styling */

/* .btn {
    cursor: pointer;
    font-size:16px;
    font-weight: 400;
    line-height: 45px;
    max-width: 160px; 
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%; 

  }


  .btn-1 {
    font-weight: 100;

 }
  .btn-1 svg {
    height: 45px;
    left: 0;
    position: absolute;
    width: 100%;

 }
  .btn-1 rect {
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-dasharray: 422, 0;
    transition: all 0.35s linear;

 }
  .btn-1:hover {
    font-weight: 900;
    letter-spacing: 1px;

 }
  .btn-1:hover rect {
    stroke-width: 5;
    stroke-dasharray: 15, 310;
    stroke-dashoffset: 48;
    transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);

 }  */

/* Another attempt at hero */

/* ~~~~~~~ INIT. BTN ~~~~~~~ */

.btnHero {
  position: relative;
  padding: 0rem 1rem;
  /* padding-right: 3.1rem; */
  font-size: 1rem;
  color: #04d9ff;
  /* letter-spacing: 1.1rem; */
  /* text-transform: uppercase; */
  transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
  cursor: pointer;
  user-select: none;
  margin-right: 1.5rem;

  border-left: 3px solid #04d9ff;

  /* border-left: 3px solid rgba(4, 217, 255, 0) */
}

.arrowDown {
  margin-top: 0.4rem;
}

.btnHero:before,
.btnHero:after {
  content: "";
  position: absolute;
  transition: inherit;
  z-index: -1;
}

.btnHero:before {
  /* border-left: 3px solid #04d9ff; */
  height: 100%;
  /* left: -3px; */
}

.btnHero:hover {
  color: white;
  transition-delay: 0.5s;
  /* border-left: 3px solid #04d9ff; */
}

.btnHero:hover:before {
  transition-delay: 0s;
}

.btnHero:hover:after {
  background: #04d9ff;
  transition-delay: 0.35s;
}

/* From Left */

.from-left:before,
.from-left:after {
  top: 0;
  width: 0;
  height: 100%;
}

.from-left:before {
  left: -3px;
  border-top: 3px solid #04d9ff;
  border-bottom: 3px solid #04d9ff;

  top: -3px;

  /* border-radius: 3px; */
  border-right: 3px solid #04d9ff;
}

.from-left:after {
  left: 0;
}

.from-left:hover:before,
.from-left:hover:after {
  width: 100%;
}

@media screen and (max-width: 320px) {
  .arrowDown {
    /* font-size: 0.1rem; */
    height: 26px;
  }
}

@media screen and (max-width: 320px) {
  .btnHero {
    /* font-size: 0.1rem; */
    padding: 0rem 0.5rem;
  }
}

/* Hero Arrows */

.arrowContainer {
  position: relative;
  width: 50px;
  height: 50px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 60px;
  margin-bottom: -20px;
  margin-right: 1.8rem;
}

.chevron {
  position: absolute;
  width: 28px;
  height: 8px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3.8s ease-out infinite;
}

/* .arrowContainer:hover .chevron:before {
  background: #04d9ff;
}

.arrowContainer:hover .chevron:after {
  background: #04d9ff;
} */

.chevron:first-child {
  animation: move 3.8s ease-out 0.3s infinite;
}

.chevron:nth-child(2) {
  animation: move 3.8s ease-out 0.7s infinite;
}

.chevron:before,
.chevron:after {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: white;

  transition: 0.5s;

  animation: colorChange 3.8s ease-out infinite;
}

.chevron:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}

@keyframes move {
  25% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    transform: translateY(30px);
  }
  80% {
    opacity: 1;
    transform: translateY(40px);
  }

  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}

@keyframes pulse {
  to {
    opacity: 1;
  }
}

@keyframes colorChange {
  80% {
    background: #04d9ff;
  }
}

/* Scrollbar */

::-webkit-scrollbar {
  width: 15px;
  background-color: black;
}
::-webkit-scrollbar-thumb {
  /* background: linear-gradient(transparent, #04d9ff); */
  background: rgba(4, 44, 84);
  border-radius: 2px;
  border: black 2px solid;
}

/* ::-webkit-scrollbar-button {
  height: 20px;
} */

/* ::-webkit-scrollbar:hover {
  background: #04d9ff;
} 

.container::-webkit-scrollbar {
  width: 14px;
}
.container::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 7px;
  box-shadow: inset 0 0 0 10px;
}
.container::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.container::-webkit-scrollbar-corner {
  background-color: transparent;
} */

/* Dropdown animation */

#menuToggle {
  display: none;
}

@media screen and (max-width: 768px) {
  #menuToggle {
    position: absolute;

    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    margin-top: 0.35rem;
    margin-right: 1.9rem;
  }
}

@media screen and (max-height: 400px) {
  #menuToggle {
    position: absolute;

    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    margin-top: 0.35rem;
    margin-right: 1.9rem;
  }
}

@media screen and (max-height: 400px) {
  .link--yaku {
    display: none;
  }
}

/* @media screen and (max-width: 800px) {
  #menuToggle {
    right: 10rem
  }
} */

#menuToggle input {
  display: flex;
  width: 60px;
  height: 60px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
}

#menuToggle span {
  /* width: 35px;
  height: 5px; */

  width: 35px;
  height: 5px;

  margin-bottom: 5px;
  background: white;
  border-radius: 4px;
  transition: all 0.5s cubic-bezier(0.08, 0.81, 0.87, 0.71);
}

#span1 {
  /* transform-origin: 5px -3px; */
  transform-origin: 5px 1.9px;
}

#span3 {
  transform-origin: bottom right;
}

#menuToggle input:checked ~ #span1 {
  background-color: #04d9ff;
  transform: rotate(45deg) translate(4px);
}
#menuToggle input:checked ~ #span2 {
  background-color: #04d9ff;
  transform: rotate(495deg) translate(2px);
}
#menuToggle input:checked ~ #span3 {
  background-color: #04d9ff;
  transform: rotate(45deg);
  opacity: 0;
}

/* #menuToggle input:checked ~ #span1 {
  background-color: #04d9ff;
  transform: rotate(45deg) translate(8px);
}
#menuToggle input:checked ~ #span2 {
  background-color: #04d9ff;
  transform: rotate(495deg) translate(4px);   
}
#menuToggle input:checked ~ #span3 {
  background-color: #04d9ff;
  transform: rotate(45deg);
  opacity: 0;
} */

/* About ani */

.wrap-container::-webkit-scrollbar {
  display: none;
}

#scroll-container {
  /* overflow: hidden; */
  /* max-width: 100%;
  margin: auto;
  height: 50vh;
  top: 25vh;
  position: relative;
  min-height: 200px; */
  width: 200px;
}
.wrap-container {
  /* position: relative; */
  max-height: 200px;
  /* width: 100%;
  max-width: 100%; */
  /* margin: auto; */
  overflow: auto;
  padding-right: 20px;
  /* mask: url(#masking); */
  -webkit-mask-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.5, rgba(0, 0, 0, 1)),
    color-stop(1, rgba(0, 0, 0, 0))
  );
}
.wrap-container ul {
  list-style: none;
  position: relative;
  transition: transform 0.3s;
  top: 50px;
}
.wrap-container ul,
.wrap-container ul li {
  margin: 0;
  padding: 0;
  text-align: center;
}
.wrap-container ul li {
  height: 50px;
  line-height: 50px;
  transition: transform 0.3s;
  /* overflow: hidden; */
}
.wrap-container ul li:last-of-type {
  padding-bottom: 50px;
}
.wrap-container ul li.active .item {
  transform: scale(1.9);
}
.wrap-container ul li .item {
  position: relative;
  transition: transform 200ms;
  display: inline-block;
}

/* New personal */

/* .aboutWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

}

.hero {
  background-size: cover;
  padding: 4rem 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  align-items: center;

}

.hero > * {
  color: white;
}

.hero > h1 {
  font-size: 4rem;
  padding-bottom: 1rem;
}

.hero > article > p {
  font-size: 1.5rem;
  font-weight: 200;
}

.hero > article > a {
  padding: 1rem;
  margin-top: .75rem;
}

.breweries {
  padding: 2rem;
}

.breweries > ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 2rem;
}

.breweries > ul > li > figure {
  max-height: 30rem;
  overflow: hidden;
  position: relative;


}

.breweries > ul > li > figure > img {
  width: 100%;
}

.aboutWrapper {
  min-height: 100vh;
}

.aboutTitle {
  margin-top: 5rem;
} */

/* New about 2 */

.aboutTitle {
  margin-top: 5.5rem;
}

.aboutWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  align-items: center;

  margin-top: 7rem;
}

/* @media (max-width: 1260px) {
  .aboutWrap {
    flex-direction: column;
  }
} */

/* @media (max-width: 1450px) {
  .aboutWrap {
    flex-direction: column;
  }
} */

/* @media (max-width: 1090px) {
  .beforeImg {
    width: 400px;
  }
} */

.effect-apollo {
  /* width: 400px; */
}

/* .about {
  height: 100vh;
} */

@media (min-width: 1650px) {
  .aboutWrap {
    padding-left: 9rem;
    padding-right: 9rem;
  }
}

@media (max-width: 1000px) {
  .aboutWrap {
    padding-left: 0rem;
    padding-right: 0rem;
  }
}

/* New about hover */

figure.snip1104 {
  /* font-family: 'Raleway', Arial, sans-serif; */
  position: relative;
  overflow: hidden;
  /* margin: 10px; */
  /* min-width: 220px; */
  max-width: 31rem;
  max-height: 30rem;

  /* height: 27rem; */
  width: 100%;
  background: #000000;
  color: #ffffff;
  text-align: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);

  /* text-align: left; */
}

@media screen and (max-width: 450px) {
  figure.snip1104 {
    max-width: 21rem;
  }
}

.red1 {
  margin-right: 1.5rem;
}

.red2 {
  margin-left: 1.5rem;
}

@media screen and (max-width: 1200px) {
  .red1,
  .red2 {
    margin-right: 0;
    margin-left: 0;
  }
}

figure.snip1104 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

figure.snip1104 img {
  max-width: 100%;
  position: relative;
  opacity: 0.4;

  /* height: 27rem; */
}

figure.snip1104 figcaption {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

figure.snip1104 h2 {
  position: absolute;
  left: 40px;
  right: 40px;
  display: inline-block;
  background: #000000;
  -webkit-transform: skew(-10deg) rotate(-10deg) translate(0, -50%);
  transform: skew(-10deg) rotate(-10deg) translate(0, -50%);
  padding: 12px 5px;
  margin: 0;
  top: 50%;
  text-transform: uppercase;
  font-weight: 400;
}

figure.snip1104 h2 span {
  font-weight: 800;
}

figure.snip1104:before {
  height: 92%;
  width: 100%;

  top: 0;
  left: 0;
  content: "";
  background: #ffffff;
  position: absolute;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: rotate(110deg) translateY(-50%);
  transform: rotate(110deg) translateY(-50%);
}

/* @media screen and (min-width: 1450px) {
  .figure.snip1104:before {
    height: 20rem;
  }
} */

figure.snip1104 a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 1;
}

figure.snip1104.blue {
  background: #123851;
}

figure.snip1104.blue h2 {
  background: #0a212f;
}

figure.snip1104.red {
  /* background: #581a14; */
  background: rgba(4, 44, 84, 010);
  margin-bottom: 1rem;
}

figure.snip1104.red h2 {
  background: #04d9ff;
}

/* figure.snip1104.yellow {
  background: #7f5006;
}

figure.snip1104.yellow h2 {
  background: #583804;
} */

figure.snip1104:hover img,
figure.snip1104.hover img {
  opacity: 1;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  filter: brightness(40%);
}

figure.snip1104:hover h2,
figure.snip1104.hover h2 {
  -webkit-transform: skew(-10deg) rotate(-10deg) translate(-150%, -50%);
  transform: skew(-10deg) rotate(-10deg) translate(-150%, -50%);
}

figure.snip1104:hover:before,
figure.snip1104.hover:before {
  -webkit-transform: rotate(110deg) translateY(-150%);
  transform: rotate(110deg) translateY(-150%);
}

/* About hover testing */

.aboutSpan {
  position: absolute;
  /* top: 3%;
  left: 3%; */
  bottom: 10%;
  left: 2%;
  /* display: none; */
  opacity: 0;
  transition: all 5s ease;

  width: 96%;
}

@media (max-width: 420px) {
  .aboutSpan {
    font-size: 13.5px;
  }
}

@media (max-width: 300px) {
  .aboutSpan {
    font-size: 11.4px;
  }
}

@media (min-width: 1000px) {
  .aboutSpan {
    font-size: 17px;
  }
}

figure.snip1104:hover .aboutSpan {
  /* display: inline-block */
  opacity: 1;
}

/* Hover conversion */

/* @media screen and (max-width: 1450px) {
  .aboutTitle {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
} */

.finalMessage {
  margin-top: 2rem;
  font-size: 20px;
  margin-left: 0.4rem;
  text-align: center;
}

@media screen and (min-width: 1200px) {
  .finalMessage {
    margin-top: 6rem;
  }
}

.centralColumn {
  margin-left: 1rem;
  margin-right: 1rem;
}

@media screen and (max-width: 1200px) {
  .about {
    height: auto;
  }
}

@media (max-width: 1200px) {
  .aboutWrap {
    flex-direction: column;
  }
}

/* .scroll-btn-skills {
  top: 50rem;

} */

/* About changing text */

#aboutContainer {
  /* position: absolute; */
  /* margin: auto; */
  width: 100vw;
  /* height: 80pt; */
  /* top: 0; */
  /* bottom: 0; */

  filter: url(#threshold) blur(0.6px);
  margin-top: 3.7rem;
  margin-bottom: 3.7rem;
}

#text1,
#text2 {
  position: absolute;
  width: 100%;
  display: inline-block;

  /* font-family: "Raleway", sans-serif; */
  /* font-size: 80pt; */

  font-size: clamp(2rem, 4vw, 3rem);

  text-align: center;

  user-select: none;
}
